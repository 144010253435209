<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Items table</h3>

	<p>This table lists all items in the framework. The <span v-html="link('treeview', '“tree”')"></span> and <span v-html="link('tileview', '“tile”')"></span> views are generally more useful for browsing and exploring {{comp_noun+'s'}}, but sometimes you just want to see your data all together in a table. You can also export associations to a spreadsheet from here.</p>
	<img srcset="/docimages/tableitemsview-2.png 3x" class="k-help-img block">
	<p><img srcset="/docimages/kebab_menu-1.png 4x" class="k-help-img float-right">To view the items table, click the <span v-html="link('kebab_menu', '“kebab” menu')"></span> in the upper-right corner of the window and click TABLE from the Viewer Mode toggle button. Then click ITEMS on the toggle button just below the kebab menu.<img srcset="/docimages/tableitemsview-1.png 3x" class="k-help-img block"></p>
	<p><b>NOTE:</b> table views are not available in browser windows less than 960 pixels wide.</p>

	<ul>
		<li>Click “SHOW FILTERS” to see options for which item types to show in table rows, and which item fields to show in table columns.<ul>
			<li>Select the “Tree Sequence” field to show a column in the table that indicates where each item appears in the framework hierarchy (some systems call this the “smart level”). For example, in the screenshot above:<ul>
				<li>The “Grade Level” item with sequence “1” is the very first top-level item in the framework.</li>
				<li>The “Course” item with sequence “1.1” is the first child of that Grade Level item.</li>
				<li>The “Domain” and “Cluster” that follow with sequence “1.1.1” and “1.1.1.1” are the first child and grandchild of that Course.</li>
				<li>The “Cluster” with sequence “1.1.1.2” in the final row of the screenshot is a sibling of the other Cluster in the screenshot.</li>
			</ul></li>
		</ul></li>
		<li>Click column headings to sort the table, and enter text in the “Search” box to limit to rows matching the search text.</li>
		<li>Click an item’s Full Statement to show its expanded tile.<ul><li>Or hold down the COMMAND (Mac)/ALT (Windows) key while clicking the Full Statement to reveal the item in the Tree View.</li></ul></li>
		<li>Click the <b><v-icon small class="mr-1">fas fa-file-arrow-down</v-icon>EXPORT button</b> to download the data currently showing to a spreadsheet (in CSV format). The downloaded spreadsheet:<ul>
			<li>will include columns for each of the currently-showing fields (as selected via the SHOW FILTERS options),
			<li>and will include rows for each currently-visible item (i.e., item types currently hidden via the SHOW FILTERS options will not be exported; also, if you’ve entered terms in the search box, only items that match those terms will be exported).</li>
		</ul></li>
		<li>Click the <v-icon small>fas fa-tree</v-icon> button in the top-right corner of the window to return to the default <span v-html="link('treeview', '“tree view”')"></span>.</li>
	</ul>
</div></template>

<script>
import HelpMixin from './HelpMixin'
export default {
	mixins: [HelpMixin],
}
</script>

<style lang="scss">
</style>
